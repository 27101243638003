<template>
  <div class="what">
    <h2 class="title">Pourquoi utiliser la gestion locative en ligne Horiz.io ?</h2>
    <div class="presentation-container">
      <div class="presentation bg-purple-light3">
        <div class="block-list">
          <div
            class="block flex-col flex-center"
            v-for="(block, $index) in blocks"
            :key="$index"
          >
            <img
              :src="block.img"
              :alt="block.txt"
              width="33"
              height="26"
              loading="lazy"
            />
            <h3
              class="text"
              v-html="block.txt"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blocks: [
        {
          img: '/imgs/pages/gestion/clock.png',
          txt: 'Simplicité et<br>gain de temps',
        },
        {
          img: '/imgs/pages/gestion/house.png',
          txt: 'Aucune commission<br>sur les loyers',
        },
        {
          img: '/imgs/pages/gestion/rocket-launch.png',
          txt: 'Boost de la<br>rentabilité',
        },
        {
          img: '/imgs/pages/gestion/scale-balanced.png',
          txt: 'Conformité des<br>documents locatifs',
        },
        {
          img: '/imgs/pages/gestion/bubble.png',
          txt: 'Equipe dispo par<br>chat et email',
        },
        {
          img: '/imgs/pages/gestion/infinity.png',
          txt: 'Accès illimité au simulateur<br>de rendement locatif',
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.what {
  .app-padding();

  padding-top: 50px;
  padding-bottom: 50px;

  .title,
  .subtitle {
    text-align: center;
    transition-delay: 100ms;
  }

  .title {
    font-size: 24px;
    font-weight: var(--ds-weight-semi-bold);
    color: var(--ds-color-primary-100);
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
  }
  .presentation {
    margin: 40px 0;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 27px;
    position: relative;

    .block-list {
      display: flex;
      flex-wrap: wrap;
      .block {
        flex: 0 0 50%;
        margin: 30px 0;
        img {
          overflow: hidden;
          height: auto;
        }
        .text {
          margin-top: 15px;
          font-size: 16px;
          text-align: center;
          font-weight: normal;
        }
      }
    }
  }

  @media @bp-desktop {
    :deep(.section-container) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-size: 32px;
      margin-bottom: 24px;
    }
    .subtitle {
      margin-top: 0;
      font-size: 22px;
      margin-bottom: 60px;
    }
    .presentation-container {
      max-width: @bp-desktop-max-width;
      width: 100%;
      margin: 0 auto;
    }
    .presentation {
      margin: 60px 0;
      flex-direction: row;
      width: 100%;
      padding: 15px 30px;
      position: relative;
      height: 332px;
      margin-bottom: 0;
      align-self: flex-end;

      .block-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        flex-grow: 1;

        .block {
          flex: 0 0 33.32%;
          font-size: 16px;
          img {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
